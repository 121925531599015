.app-polaris-select-input {
  .Polaris-Select {
    height: 28px;
  }
  .Polaris-Select__Backdrop {
    border-color: #e3e3e3;
  }
  .custom-select-height{
    height: 28px;
  }
}
