.custom-menu-paper-select {
  max-height: 250px;
  max-width: 150px;
}
.custom-menu-paper-select .Mui-selected {
  background-color: #d5d2d3;
}

.chip-container {
  position: relative;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  /* z-index: 9999; */
}

.custom-menu-paper {
  max-height: 150px;
}
.custom-menu-paper .Mui-selected {
  background-color: #d5d2d3;
}
