.account-details-label {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #303030;
  margin-top: 12px;
}
.transfer-details-value {
  font-family: Inter;
  font-size: 13px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  font-variation-settings: 'slnt' 0;
  color: #8d8585 !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  word-break: break-all;
  margin-top: 10px;
  margin-bottom: 20px;
}
