.h-100 {
  height: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.mt-36px {
  margin-top: 36px;
}
.font-13px {
  font-size: 13px;
}
.mt-10px {
  margin-top: 10px;
}
.label-violet {
  color: #bb6bd9;
}

.label-pale-green {
  color: #4ecd4e;
}

.label-pale-yellow {
  color: #f9a408;
}
