.trade-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.list-heading {
    font-size: 16px !important;
    color: #8d8585 !important;
    font-weight: 550;
    line-height: 20px !important;
    letter-spacing: 0px;
    text-align: left;
    font-variation-settings: 'slnt' 0;
}

.list-value {
    font-size: 13px !important;
    color: #8d8585 !important;
    margin-top: 5px;
    font-weight: 500;
    line-height: 20px !important;
    letter-spacing: 0px;
    text-align: left;
    font-variation-settings: 'slnt' 0;
}
