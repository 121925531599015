.product-list {
  font-size: 12px;
  color: #303030;
  line-height: 20px;
  font-weight: 450;
}
.product-list-title {
  font-size: 13px;
  color: #637381;
  line-height: 20px;
  font-weight: 500;
}
