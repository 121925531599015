.customer-details {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-bottom: 10px;
  margin-left: 0px;
}
.status-val {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #8d8585;
}
.order-type {
  font-size: 14px;
  color: #212b36;
  font-weight: 500;
}
.status-label {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  /* color: #969592; */
  margin-bottom: 10px;
  margin-left: 0px;
}

.detail-label {
  color: #212b36;
  font-weight: 500;
}
.detail-value {
  color: #212b36;
  font-size: 14px;
  font-weight: 400;
}
.rtl {
  direction: rtl;
  text-align: right;
}

.tileHeading {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #303030;
  margin-top: 10px;
}
.tileValues {
  font-family: Inter;
  font-size: 12px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  font-variation-settings: 'slnt' 0;
  /* color: #a5a4a2; */
  color: #8d8585 !important;
  display: -webkit-box; /* Required for -webkit-line-clamp */
  -webkit-box-orient: vertical; /* Required for -webkit-line-clamp */
  -webkit-line-clamp: 3; /* Limit the text block to two lines */
  overflow: hidden; /* Hide the overflow text */
  text-overflow: ellipsis; /* Add an ellipsis at the end */

  width: 100%; /* Set a fixed width or a maximum width */
  /* max-width: 200px; */
  word-break: break-all;
}

.address-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #303030;
  margin-top: 10px;
}

.label-red {
  color: red;
}
.label-green {
  color: green;
}
.label-yellow {
  color: #c19c2d;
}
.status-btn {
  position: absolute;
  top: 20px;
  right: 22px;
}

.address-heading {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin-bottom: 10px;
  margin-left: 0px;
}

.name-title {
  font-family: Inter;
  font-size: 12px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  font-variation-settings: 'slnt' 0;
  color: #8d8585 !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  word-break: break-all;
}

.boxContent {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.2em; /* Adjust as needed */
  max-height: 3.6em; /* Line height multiplied by number of lines */
}

.singleLineContent {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.2em; /* Adjust as needed */
  max-height: 3.6em;
}
