.Polaris-Navigation__Item,
.Polaris-Navigation__ItemInnerWrapper:not(
    .Polaris-Navigation__ItemInnerWrapper--selected
  ) {
  border-radius: 0 !important;
}
.Polaris-Navigation__ItemInnerWrapper {
  background: #ebebeb;
}
.Polaris-Navigation__ListItem {
  margin-bottom: 8px;
}
.Polaris-Navigation__ItemInnerWrapper--selected {
  background: #222222 !important;
}
.Polaris-Navigation__Text {
  font-size: 13px !important;
  color: #303030 !important;
  font-weight: 550;
  line-height: 20px !important;
  letter-spacing: 0px;
  text-align: left;
  font-variation-settings: 'slnt' 0;
}
.Polaris-Navigation__ItemInnerWrapper--selected .Polaris-Navigation__Text {
  color: #ffffff !important;
  font-weight: 550 !important;
}
.Polaris-Navigation__ItemInnerWrapper--selected .Polaris-Navigation__Icon svg {
  fill: white !important;
}

.Polaris-Navigation__Section {
  height: calc(100vh - 90px);
  overflow-y: auto;
  scroll-behavior: smooth;
}