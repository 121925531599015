.custom-app-polaris-select-input {
  .Polaris-Select {
    height: 28px;
  }
  .Polaris-Select__Backdrop {
    height: 40px;
  }
  .custom-select-height {
    height: 28px;
  }
  .Polaris-Select__Content {
    height: 40px;
    border-color: #e3e3e3;
    border-radius: 7px;
  }
}
