.btn-info {
    background-color: #E3CA7E !important;
    border: none !important;
    color: #4a4a4a;
    margin-top: 10px;
    width: 100%;
}

#root__title {
    font-size: 17px !important;
    font-weight: 700;
    margin-top: 10px;
}

.input-field-rjsf {
    margin-bottom: 10px !important;
}

.input-field-rjsf>label{
    font-size: 14px;
    margin-bottom: 3px;
}