.auth-input-container {
    .Mui-disabled {
      -webkit-text-fill-color: #A2A19F;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  