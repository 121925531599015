.card-title-label {
  font-family: Inter;
  font-size: 17px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: rgb(97, 97, 97);
  margin-top: 10px;
  margin-bottom: 10px;
}

.sub-title-label {
  font-family: Inter;
  font-size: 14px;
  font-weight: 450;
  line-height: 16px;
  text-align: left;
  color: #dfd9d9;
}
.sub-title-value {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 5px;
  text-align: left;
  color: white;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.cardicon-dashboard {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
