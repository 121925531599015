.tileHeading {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #303030;
  margin-top: 10px;
  word-break: break-all;

  font-variation-settings: 'slnt' 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 200px;
}
.tileValue {
  font-family: Inter;
  font-size: 12px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  font-variation-settings: 'slnt' 0;
  color: #8d8585 !important;
  display: -webkit-box; /* Required for -webkit-line-clamp */
  -webkit-box-orient: vertical; /* Required for -webkit-line-clamp */
  -webkit-line-clamp: 2; /* Limit the text block to two lines */
  overflow: hidden; /* Hide the overflow text */
  text-overflow: ellipsis; /* Add an ellipsis at the end */

  width: 100%; /* Set a fixed width or a maximum width */
  max-width: 200px;
  word-break: break-all;
}
.product-details {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #969592;
  margin-bottom: 10px;
}
