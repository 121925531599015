.file-upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 140px;
  background-color: #fcfaf2;
  border: 0.66px dotted #c3c3c3;
}
.pdf-border {
  border: 0.66px dotted #c3c3c3;
}
.drop-title {
  margin-top: 5px;
  font-size: 14px;
  color: #c3c3c3;
}
.asterisk-content::after {
  content: '*';
  color: #c24622;
  margin-right: 0.25em;
  font-size: 22px;
  line-height: 0;
  position: relative;
  top: 7px;
  left: 2px;
}
.image-box-container {
  position: relative;
  height: 60px;
  width: 60px;
  background-color: #f0f0f0;
  cursor: pointer;
  position: relative;
}
.close-icon {
  position: absolute;
  right: 0px;
  top: 0px;
}
.required-label {
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 10px;
}
.file-upload-title {
  font-weight: 500;
  font-family: Montserrat;
  color: #424242;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;
}

.image-box-container:hover .close-img {
  height: 18px;
  width: 18px;
}
.rtl {
  direction: rtl;
  text-align: right;
}

.img-asterisk-content::before {
  content: '*';
  color: #c24622;
  margin-right: 0.25em;
  font-size: 22px;
  line-height: 0;
  position: relative;
  top: 7px;
  left: 2px;
}
