.bulk-upload-center {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.download-line {
  font-size: 14px;
  color: #637381;
}
.button-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  /* margin-top: 20px; */
  margin-bottom: 10px;
}
