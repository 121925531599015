.app-date-range-picker {

  position: relative;
  .react-datepicker {
    min-width: max-content;
  }
  .react-datepicker__input-container {
    min-width: 250px;
  }

  .react-datepicker-wrapper {
    border: 0;
    display: inline-block;
    padding: 0;
    width: 100%;
}

  input {
    height: 28px;
    border-radius: 8px;
    width: 100%;
    font-size: 12px;
    border-width: 0.66px;
    box-shadow: 1px 1px 4px #e3e3e3;
    padding-top: 4px;
    padding-bottom: 4px;
    border: 1px solid #e3e3e3;
    padding: 6px;
    z-index: 2;
  }
  input:focus {
    border-color: #e3e3e3;
    outline: none;
  }
  .cal-icon {
    position: absolute;
    right: 26px;
    top: 1px;
  }
  .range-placeholder {
    position: absolute;
    left: 26px;
    top: 5px;
    font-size: 12px;
    z-index: 1;
    color: #808080;
  }
}
