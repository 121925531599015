.Polaris-RadioButton__Backdrop {
  background: #ebebeb !important;
  border: none !important;
}

.Polaris-RadioButton__Input + .Polaris-RadioButton__Backdrop::before,
.Polaris-RadioButton__ChoiceLabel:hover
  .Polaris-RadioButton__Input
  + .Polaris-RadioButton__Backdrop::before {
  background-color: #ffffff !important;
  transition: opacity var(--p-motion-duration-150) var(--p-motion-ease-out),
    transform var(--p-motion-duration-150) var(--p-motion-ease-out);
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop::before,
.Polaris-RadioButton__ChoiceLabel:hover
  .Polaris-RadioButton__Input:checked
  + .Polaris-RadioButton__Backdrop::before {
  background-color: #E3CA7E !important;
}

.Polaris-Choice {
  margin-right: 24px !important;
}
.Polaris-Choice:last-child {
  margin-right: 0 !important;
}
